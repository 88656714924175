import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {UsersApi} from '../../../api/user-api/users-api';
import {IUserDto, UserType} from '../../../api/DTOs/IUserDto';
import {IContractorDto} from '../../../api/contractor-api/IContractorDto';
import {Preloader} from '../../../components/preloader';
import SVG from 'react-inlinesvg';
import {useModalEditUser} from '../../../components/modals/edit-user/modal-edit-user-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {PaymentRequisitesApi} from '../../../api/payment-requisite-api/payment-requisites-api';
import {
  IPaymentRequisitesDto,
  PaymentRequisitesHistory,
} from '../../../api/payment-requisite-api/IPaymentRequisitesDto';
import {
  useModalEditPaymentRequisites,
} from '../../../components/modals/edit-requisites/modal-edit-requisites-admin-context';
import {AdminRoutes} from '../../../../configs/routes';
import {
  useModalGenerateFinancialReport,
} from '../../../components/modals/generate-financial-report/modal-generate-financial-report-context';
import {PaymentRequisitesInfo} from '../../../components/payment-requisites-info';
import {ContractorInfo} from '../../../components/contractor-info';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../hooks/use-loading';
import {TIMESTAMP_CONFIGS, Timestamps} from '../../../components/timestamps';
import {BootstrapColor} from '../../../styles/styles';
import {PaymentRequisitesHistoryBlock} from './payment-requisites-hisory';
import {ICONS} from '../../../images/images';
import cn from 'classnames';

export const UserPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const userId = match.params.id;

  const api = new UsersApi();
  const requisitesApi = new PaymentRequisitesApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    signature: true,
    paymentRequisites: true,
    paymentRequisitesHistory: true,
  });

  const [user, setUser] = useState<IUserDto | null>(null);
  const [contractor, setContractor] = useState<IContractorDto | null>(null);
  const [paymentRequisites, setPaymentRequisites] = useState<IPaymentRequisitesDto | null>(null);
  const [paymentRequisitesHistory, setPaymentRequisitesHistory] = useState<Array<PaymentRequisitesHistory>>([]);

  const {setBreadcrumb} = useCustomBreadcrumbs();
  const {showEditUserModal} = useModalEditUser();
  const {showEditPaymentRequisitesAdminModal} = useModalEditPaymentRequisites();
  const {showGenerateFinancialReportModal} = useModalGenerateFinancialReport();
  const {showConfirmActionModal} = useModalConfirmAction();

  useEffect(() => {
    Promise.all([fetchUser(), fetchPaymentRequisites(), fetchPaymentRequisitesUpdateHistory()]).then();
  }, []);

  useEffect(() => {
    if (user?.email) {
      setBreadcrumb(user.email);
    }
  }, [user?.email]);

  const fetchUser = async () => {
    try {
      startLoading('page');
      const result = await api.getUser(Number(match.params.id));
      setUser(result.data.item);
      setContractor(result.data.relations.contractor || null);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchPaymentRequisites = useCallback(async () => {
    try {
      startLoading('paymentRequisites');
      const result = await requisitesApi.getUserPaymentRequisites(userId);
      setPaymentRequisites(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('paymentRequisites');
    }
  }, []);

  const fetchPaymentRequisitesUpdateHistory = useCallback(async () => {
    try {
      startLoading('paymentRequisitesHistory');
      const result = await requisitesApi.getUserPaymentRequisitesHistory(userId);
      setPaymentRequisitesHistory(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('paymentRequisitesHistory');
    }
  }, []);

  const handleEditPaymentRequisitesClick = async () => {
    const result = await showEditPaymentRequisitesAdminModal({
      userId: match.params.id,
      requisites: paymentRequisites,
      user: user as IUserDto,
    });

    if (result.reason === CloseModalReason.OK) {
      await fetchPaymentRequisites();
    }
  };

  const handleEditInfoAboutUserClick = async () => {
    if (!user) {
      return;
    }

    const result = await showEditUserModal(user);
    if (result.reason === CloseModalReason.OK) {
      await fetchUser();
    }
  };

  const handleResetPassword = async () => {
    try {
      if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_RESET_PASSWORD_USER'}))) {
        await api.resetPasswordForUser(Number(match.params.id));
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleChangeLockStatus = async () => {
    if (!user) {
      return;
    }

    try {
      if (user.blocked_at !== null) {
        if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_UNBLOCK_USER'}))) {
          await api.unblockUser(Number(match.params.id));
          await fetchUser();
        }
      } else {
        if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_BLOCK_USER'}))) {
          await api.blockUser(Number(match.params.id));
          await fetchUser();
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleDeleteClick = async () => {
    try {
      if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_USER'}))) {
        await api.deleteUser(Number(match.params.id));
        history.push(AdminRoutes.getUserManagementRoute());
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleDownloadClick = async () => {
    await showGenerateFinancialReportModal(Number(userId));
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (user === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  const renderButtons = () => {
    if (!user) {
      return null;
    }

    return (
      <Toolbar
        items={[
          {
            title: intl.formatMessage({id: 'EDIT'}),
            type: 'BUTTON',
            icon: ICONS.EDIT,
            className: 'btn btn-light-success font-weight-bolder',
            onClick: handleEditInfoAboutUserClick,
          },
          {
            title: intl.formatMessage({id: 'RESET_PASSWORD'}),
            type: 'BUTTON',
            icon: ICONS.RESET,
            className: 'btn btn-light-primary font-weight-bolder',
            onClick: handleResetPassword,
          },
          {
            title: user.blocked_at !== null ? intl.formatMessage({id: 'UNBLOCK'}) : intl.formatMessage({id: 'BLOCK'}),
            type: 'BUTTON',
            icon: user.blocked_at !== null ? ICONS.UNBLOCK : ICONS.BLOCK,
            className: 'btn btn-light-warning font-weight-bolder',
            onClick: handleChangeLockStatus,
          },
          {
            title: intl.formatMessage({id: 'DELETE'}),
            type: 'BUTTON',
            icon: ICONS.DELETE,
            className: 'btn btn-light-danger font-weight-bolder',
            onClick: handleDeleteClick,
          },
          {
            title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
            type: 'BUTTON',
            icon: ICONS.DOWNLOAD,
            className: 'btn btn-secondary font-weight-bolder',
            onClick: handleDownloadClick,
          },
        ]}
      />
    );
  };

  const renderPaymentRequisites = () => {
    if (loadings.paymentRequisites) {
      return <Preloader />;
    }

    return (
      <>
        <div className={'font-size-h4 font-weight-boldest my-5'}>
          <FormattedMessage id={'PAYMENT_REQUISITES'} />
          <span
            onClick={handleEditPaymentRequisitesClick}
            className={`svg-icon svg-icon-lg svg-icon-light-secondary ml-2 cursor-pointer`}>
            <SVG src={ICONS.EDIT} />
          </span>
        </div>
        <PaymentRequisitesInfo paymentRequisites={paymentRequisites} />
      </>
    );
  };

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                <FormattedMessage id={'EMAIL'} />: {user.email}
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-0'}>
                <FormattedMessage id={'TYPE'} />:{' '}
                <span className={user.type === UserType.ADMIN ? 'text-primary' : 'text-secondary'}>
                  <FormattedMessage id={user.type} />
                </span>
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-0'}>
                <FormattedMessage id={'WITHDRAWAL_BY_REQUISITES'} />:{' '}
                <span className={cn(user.allow_payment_request_by_requisites ? 'svg-icon-success' : 'svg-icon-danger', 'svg-icon')}>
                  <SVG src={user.allow_payment_request_by_requisites ? ICONS.CHECK : ICONS.CROSS} />
                </span>
              </p>
            </div>
            <Timestamps
              items={[
                {
                  title: intl.formatMessage({id: 'CREATED_AT'}),
                  date: user.created_at,
                  format: TIMESTAMP_CONFIGS.DEFAULT,
                  color: BootstrapColor.SUCCESS,
                },
                {
                  title: intl.formatMessage({id: 'UPDATED_AT'}),
                  date: user.updated_at,
                  format: TIMESTAMP_CONFIGS.DEFAULT,
                  color: BootstrapColor.PRIMARY,
                },
                {
                  title: intl.formatMessage({id: 'BLOCKED_AT'}),
                  date: user.blocked_at,
                  format: TIMESTAMP_CONFIGS.DEFAULT,
                  color: BootstrapColor.DANGER,
                },
              ]}
            />
          </div>
          <div className={'separator separator-solid my-7'} />
          {renderButtons()}
          <ContractorInfo contractor={contractor} />
          <div className={'separator separator-solid my-7'} />
          {renderPaymentRequisites()}

          {paymentRequisitesHistory != null && paymentRequisitesHistory.length > 0 && (
            <>
              <div className={'font-size-h4 font-weight-boldest my-5'}>
                <FormattedMessage id={'UPDATE_HISTORY'} />
              </div>
              <PaymentRequisitesHistoryBlock history={paymentRequisitesHistory} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

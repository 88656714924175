import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import {SupportTicketsApi} from '../../api/support-tickets-api/support-tickets-api';
import {IPaginationInfo} from '../../api/Paginator';
import {ISupportTicketDto} from '../../api/support-tickets-api/ISupportTicketDto';
import {ErrorStub} from '../../components/error-stub';
import {TicketsTable} from './tickets-table';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {BaseListPage} from '../base/base-list-page';
import {ApiRequestException} from '../../api/axios-instance';

interface IProps {
  isAdmin?: boolean;
}

const ICONS = {
  PLUS: require('../../images/svg/Plus.svg'),
};

const ILLUSTRATIONS = {
  WRITE: require('../../images/illustration/Write.png'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.UPDATED_AT,
  direction: SortDirection.DESC,
};

export const TicketsPages: React.FC<IProps> = ({isAdmin}) => {
  const intl = useIntl();

  const api = new SupportTicketsApi();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tickets, setTickets] = useState<Array<ISupportTicketDto>>([]);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'tickets_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      filters: [
        {
          type: FilterType.SELECT,
          name: 'read_status',
          placeholder: intl.formatMessage({id: 'READ_STATUS'}),
          options: [
            {
              label: intl.formatMessage({id: 'ONLY_NOT_READ'}),
              value: 'ONLY_NOT_READ',
            },
            {
              label: intl.formatMessage({id: 'ONLY_READ'}),
              value: 'ONLY_READ',
            },
          ],
        },
      ],
    });
  }, []);

  const fetchTickets = async (filters: IQueryParams) => {
    try {
      setLoading(true);
      const response = await api.getTickets(filters);
      setTickets(response.data.items);
      setPaginationInfo(response.data.paginator);
    } catch (e: any) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const renderSupportTickets = () => {
    if (tickets.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <TicketsTable isAdmin={isAdmin} tickets={tickets} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderMarketingBlock = () => {
    return (
      <div className="card">
        <div className="card-body p-0">
          <div className="card-px text-center pt-20 mt-10">
            <h2 className="font-weight-boldest mt-10">
              <FormattedMessage id={'TICKETS_NOT_FOUND'} />
            </h2>
            <p className="text-gray-400 font-weight-bolder mb-10 save-line-and-tabulation">
              <FormattedMessage id={'CREATE_TICKET_MARKETING_DESCRIPTION'} />
            </p>
          </div>
          <div className="text-center px-4">
            <img className={'mw-100 mh-45vh'} src={ILLUSTRATIONS.WRITE} alt="nones" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchTickets}
      showMarketing={tickets?.length === 0}
      renderMarketing={renderMarketingBlock}>
      {renderSupportTickets()}
    </BaseListPage>
  );
};

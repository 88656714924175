export interface IYoutubeFinancialBulkReportsDto {
  id: number;
  created_at: string;
  month: number;
  year: number;
  errorDescription: string | null;
  status: ReportsProcessingStatus;
  [REPORT_FILE_NAME.VIDEO_SUMMARY_FILE_NAME]: string;
  [REPORT_FILE_NAME.CLAIM_SUMMARY_FILE_NAME]: string;
  [REPORT_FILE_NAME.RED_RAWDATA_VIDEO_FILE_NAME]: string;
  [REPORT_FILE_NAME.RED_MUSIC_RAWDATA_VIDEO_FILE_NAME]: string;
  [REPORT_FILE_NAME.ADJ_CLAIM_SUMMARY_FILE_NAME]: string | null;
  [REPORT_FILE_NAME.ADJ_VIDEO_SUMMARY_FILE_NAME]: string | null;
  [REPORT_FILE_NAME.ECOMMERCE_PAID_FEATURES_FILE_NAME]: string | null;
  [REPORT_FILE_NAME.AFFILIATE_PAYMENT_SUMMARY_FILE_NAME]: string | null;
  cms_id: number;
  cms_name: string;
}

export enum REPORT_TYPE {
  VIDEO_SUMMARY = 'VIDEO_SUMMARY', // Доход от рекламы -> Сводный отчет о видео
  CLAIM_SUMMARY = 'CLAIM_SUMMARY', // Доход от рекламы -> Сводный отчет о заявках
  RED_RAWDATA_VIDEO = 'RED_RAWDATA_VIDEO', // Доход от подписки -> Отчет о немузыкальных видео
  RED_MUSIC_RAWDATA_VIDEO = 'RED_MUSIC_RAWDATA_VIDEO', // Доход от подписки -> Отчет об объектах
  ADJ_CLAIM_SUMMARY = 'ADJ_CLAIM_SUMMARY', // Доход от рекламы: компенсационные выплаты → Сводный отчет о заявках
  ADJ_VIDEO_SUMMARY = 'ADJ_VIDEO_SUMMARY', // Доход от рекламы: компенсационные выплаты → Сводный отчет о видео
  ECOMMERCE_PAID_FEATURES = 'ECOMMERCE_PAID_FEATURES', // Доход от платных функций -> Отчет о платных функциях
  SHORTS_ADS = 'SHORTS_ADS', // Доход от рекламы в YouTube Shorts -> Сводный видеоотчет о доходе от рекламы в Youtube Shorts
  SHORTS_SUBSCRIPTION = 'SHORTS_SUBSCRIPTION', // Доход от подписок в YouTube Shorts -> Сводный видеоотчет о доходе от подписок в Youtube Shorts
  AFFILIATE_PAYMENT_SUMMARY = 'AFFILIATE_PAYMENT_SUMMARY', // Налоговый отчет
}

export enum REPORT_FILE_NAME {
  VIDEO_SUMMARY_FILE_NAME = 'VIDEO_SUMMARY_FILE_NAME',
  CLAIM_SUMMARY_FILE_NAME = 'CLAIM_SUMMARY_FILE_NAME',
  RED_RAWDATA_VIDEO_FILE_NAME = 'RED_RAWDATA_VIDEO_FILE_NAME',
  RED_MUSIC_RAWDATA_VIDEO_FILE_NAME = 'RED_MUSIC_RAWDATA_VIDEO_FILE_NAME',
  ADJ_CLAIM_SUMMARY_FILE_NAME = 'ADJ_CLAIM_SUMMARY_FILE_NAME',
  ADJ_VIDEO_SUMMARY_FILE_NAME = 'ADJ_VIDEO_SUMMARY_FILE_NAME',
  ECOMMERCE_PAID_FEATURES_FILE_NAME = 'ECOMMERCE_PAID_FEATURES_FILE_NAME',
  SHORTS_ADS = 'SHORTS_ADS_FILE_NAME',
  SHORTS_SUBSCRIPTION = 'SHORTS_SUBSCRIPTION_FILE_NAME',
  AFFILIATE_PAYMENT_SUMMARY_FILE_NAME = 'AFFILIATE_PAYMENT_SUMMARY_FILE_NAME'
}

export enum ReportsProcessingStatus {
  CREATED = 'CREATED',
  IN_WORK = 'IN_WORK',
  ERROR = 'ERROR',
  PROCESSED = 'PROCESSED',
}


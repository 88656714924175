import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';
import {InputSelect} from '../../Inputs/InputSelect';
import {PaymentRequestDestination} from '../../../api/payment-api/IPaymentRequestDto';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {ModalPaymentRequestField} from './payment-request-modal-context';
import {PartialNullable} from '../../../../types';
import {useUserRole} from '../../../hooks/use-user-role';
import {Svg} from '../../../images/svg';
import {BootstrapColor} from '../../../styles/styles';
import {ICONS} from '../../../images/images';
import {MediaCubePayOAuthData} from '../../../api/user-api/users-response-contracts';
import {Preloader} from '../../preloader';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {Currency} from '../../../api/transaction-api/Transaction';

const PN_FEE_AMOUNT = 40;

interface IModalPaymentRequestProps {
  loadings: any;
  mcPayOauthInfo: MediaCubePayOAuthData | null;
  state: PartialNullable<ModalPaymentRequestField>;

  updateState: UpdateFields<ModalPaymentRequestField>;

  onHide(): void;

  onOkClick(): any;

  onAuthMcPayClick(): any;
}

export const ModalPaymentRequest: React.FC<IModalPaymentRequestProps> = ({
                                                                           state,
                                                                           loadings,
                                                                           updateState,
                                                                           onHide,
                                                                           onAuthMcPayClick,
                                                                           onOkClick,
                                                                         }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [, user] = useUserRole();
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSum = (e: any) => {
    updateState({value: e.currentTarget.value});
  };

  return (
    <>
      <BaseModal
        visible={state.visible ?? false}
        backdrop={'static'}
        loading={loading}
        disabledButtons={
          loading || (state.destination === PaymentRequestDestination.TO_MCPAY && !user.hasMcPayCredentials)
        }
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'PAYMENT_REQUEST'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={state.error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={state.error != null}
            />

            {state.destination === PaymentRequestDestination.BY_REQUISITES && (
              <AlertCustom
                text={
                  <>
                    <div>
                      {intl.formatMessage({id: 'MIN_PAYOUT_AMOUNT_IS'})}:{' '}
                      {CustomFormatter.formatMoney(500, Currency.USD)}
                    </div>
                    <div className={'font-italic'}>
                      * {intl.formatMessage({id: 'PN_TAX_INFO'})}:{' '}
                      {CustomFormatter.formatMoney(PN_FEE_AMOUNT, Currency.USD)}
                    </div>
                  </>
                }
                type={'light-info'}
                iconClassName={'svg-icon-info'}
                visible={true}
              />
            )}

            {state.destination === PaymentRequestDestination.TO_MCPAY && (
              <div className="card card-custom gutter-b bg-info p-0">
                <div className="card-body p-0">
                  <div className="d-flex p-4 text-light">
                    <div className={'w-100'}>
                      <p className={'font-weight-boldest'}>
                        <FormattedMessage id={'MC_PAY_DESCRIPTION'} />
                      </p>
                      <div className={'font-size-sm'}>
                        <div>• {intl.formatMessage({id: 'MC_PAY_DESCRIPTION_LIST_ITEM_ONE'})}</div>
                        <div>• {intl.formatMessage({id: 'MC_PAY_DESCRIPTION_LIST_ITEM_SECOND'})}</div>
                        <div>• {intl.formatMessage({id: 'MC_PAY_DESCRIPTION_LIST_ITEM_THIRD'})}</div>
                        <div className={'font-italic'}>
                          * {intl.formatMessage({id: 'MC_PAY_DESCRIPTION_LIST_ITEM_FOURTH'})}
                        </div>
                      </div>
                      <div className={'text-right'}>
                        {loadings.mcPayInformation ? (
                          <Preloader />
                        ) : user.hasMcPayCredentials ? (
                          <span className={'mt-1 font-size-sm'}>
                            <Svg src={ICONS.CHECK} color={BootstrapColor.SUCCESS} />{' '}
                            <FormattedMessage id={'MC_PAY_ALREADY_REGISTER'} />
                          </span>
                        ) : (
                          <button onClick={onAuthMcPayClick} className={'btn btn-light-info btn-sm'}>
                            <FormattedMessage id={'MC_PAY_REGISTER'} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <ValidateErrorWrapper message={getValidationErrorMessage('destination', state.validationErrors)}>
              <InputSelect
                label={intl.formatMessage({id: 'PAYMENT_REQUEST_DESTINATION'})}
                value={state.destination}
                placeholder={intl.formatMessage({id: 'INPUT_PLACEHOLDER_PAYMENT_REQUEST_DESTINATION'})}
                hasError={!!getValidationErrorMessage('destination', state.validationErrors)}
                options={[
                  {
                    value: PaymentRequestDestination.BY_REQUISITES,
                    label: intl.formatMessage({
                      id: `PAYMENT_REQUEST_DESTINATION_${PaymentRequestDestination.BY_REQUISITES}`,
                    }),
                  },
                  {
                    value: PaymentRequestDestination.TO_MCPAY,
                    label: intl.formatMessage({
                      id: `PAYMENT_REQUEST_DESTINATION_${PaymentRequestDestination.TO_MCPAY}`,
                    }),
                  },
                ]}
                onChange={value => updateState({destination: value as PaymentRequestDestination})}
              />
            </ValidateErrorWrapper>

            <div className="form-group">
              <ValidateErrorWrapper message={getValidationErrorMessage('value', state.validationErrors)}>
                <InputText
                  required
                  value={state.value}
                  type={'number'}
                  label={intl.formatMessage({id: 'SUM'}) + ' ($)'}
                  hasError={!!getValidationErrorMessage('value', state.validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={handleChangeSum}
                />
                {state.destination === PaymentRequestDestination.BY_REQUISITES &&
                  state?.value != null &&
                  state.value > 40 && (
                    <div className={'font-weight-bolder'}>
                      {intl.formatMessage({id: 'WITHDRAWAL_SUM_WITHOUT_FEE'})}:{' '}
                      <span className={'text-primary'}>
                        {CustomFormatter.formatMoney(
                          state.value ? (state?.value as number) - PN_FEE_AMOUNT : null,
                          Currency.USD,
                        )}
                      </span>
                    </div>
                  )}
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};

import {IFilterDto} from '../app/api/DTOs/IFilterDtos';
import config from './config';
import {AppLocale} from '../localization';
import {EntityId} from '../app/api/base/BaseEntity';

export class AdminRoutes {
  static routeGroupPrefix = '/admin';

  static getDashboardRoute() {
    return this.routeGroupPrefix + '/dashboard';
  }

  static getCMSRevenueRoute() {
    return this.routeGroupPrefix + '/cms-revenue';
  }

  static getContractorsRoute() {
    return this.routeGroupPrefix + '/contractors';
  }

  static getSpecifyContractorRoute(contractorId: string | number) {
    return `${this.routeGroupPrefix}/contractors/${contractorId}`;
  }

  static getContractsRoute() {
    return this.routeGroupPrefix + '/contracts';
  }

  static getMediaCubeConfirmationRoute() {
    return this.routeGroupPrefix + '/media-cube-confirmation';
  }

  static getSpecifyMediaCubeConfirmationRoute(contractorId: EntityId) {
    return `${this.routeGroupPrefix}/media-cube-confirmation/${contractorId}`;
  }

  static getSpecifyContractRoute(contractId: string | number) {
    return `${this.routeGroupPrefix}/contracts/${contractId}`;
  }

  static getUserManagementRoute() {
    return this.routeGroupPrefix + '/user-management';
  }

  static getSpecifyUserManagementRoute(userId: string | number) {
    return `${this.routeGroupPrefix}/user-management/${userId}`;
  }

  static getFinancialAccountsRoute() {
    return this.routeGroupPrefix + '/financial-accounts';
  }

  static getSpecifyFinancialAccountsRoute(accountId: string | number) {
    return `${this.routeGroupPrefix}/financial-accounts/${accountId}`;
  }

  static getPaymentRequestsRoute(filters?: IFilterDto) {
    return `${this.routeGroupPrefix}/payment-requests${this.applyFilters(filters)}`;
  }

  static getSpecifyPaymentRequestRoute(requestId: string | number) {
    return `${this.routeGroupPrefix}/payment-requests/${requestId}`;
  }

  static getConnectChannelRequestsRoute() {
    return this.routeGroupPrefix + '/connect-youtube-channels-requests';
  }

  static getSpecifyConnectChannelRequestRoute(requestId: string | number) {
    return `${this.routeGroupPrefix}/youtube-channels-requests/${requestId}`;
  }

  static getTransactionsRoute(filters?: IFilterDto) {
    return `${this.routeGroupPrefix}/transactions${this.applyFilters(filters)}`;
  }

  static getSpecifyTransactionRoute(transactionId: string | number) {
    return `${this.routeGroupPrefix}/transactions/${transactionId}`;
  }

  static getUsersActionsRoute() {
    return this.routeGroupPrefix + '/users-actions';
  }

  static getYoutubeFinancialReportsRoute() {
    return this.routeGroupPrefix + '/youtube-financial-reports';
  }

  static getUploadYoutubeFinancialReports() {
    return this.routeGroupPrefix + '/youtube-financial-reports/upload';
  }

  static getYoutubeIntegrationRoute() {
    return this.routeGroupPrefix + '/youtube-integration';
  }

  static getInstructionsRoute() {
    return this.routeGroupPrefix + '/instructions';
  }

  static getSupportRoute() {
    return this.routeGroupPrefix + '/support';
  }

  static getCreateArticleRoute(chapterId: string | number) {
    // Так как используется в разделе базы знаний используется общий компонент и общие роуты,
    // то не требуется добавлять префикс роутов администратора.
    return `/knowledge-base/${chapterId}/create`;
  }

  static getEditArticleRoute(articleId: string | number) {
    // Так как используется в разделе базы знаний используется общий компонент и общие роуты,
    // то не требуется добавлять префикс роутов администратора.
    return `/knowledge-base/${articleId}/edit`;
  }

  static getSupportTicketRoute(id: number | string) {
    return `${this.routeGroupPrefix}/support/${id}`;
  }

  static getAdvertisementRoute() {
    return this.routeGroupPrefix + '/advertisements';
  }

  static getSpecifyAdvertisementRequestRoute(id: string | number) {
    return `${this.routeGroupPrefix}/advertisements/${id}`;
  }

  static getMailingsRoute() {
    return this.routeGroupPrefix + '/mailings';
  }

  static getApplicationSettingsRoute() {
    return this.routeGroupPrefix + '/application-settings';
  }

  static getCreateMailingRoute() {
    return this.getMailingsRoute() + '/create';
  }

  static getEditMailingRoute(mailingId: string | number) {
    return `${this.getMailingsRoute()}/${mailingId}/edit`;
  }

  static getSpecifyMailingRoute(mailingId: number | string) {
    return `${this.routeGroupPrefix}/mailings/${mailingId}`;
  }

  private static applyFilters(filters: IFilterDto | undefined): string {
    if (!filters) return '';
    return (
      '?' +
      Object.keys(filters)
        .map(key => {
          return `${key}=${filters[key as keyof IFilterDto]}`;
        })
        .join('&')
    );
  }
}

export class Routes {
  static getExternalYoutubeVideoRoute(youtubeVideoId: string) {
    return `${config.YOUTUBE_VIDEO_URL}${youtubeVideoId}`;
  }

  static getExternalYoutubeChannelRoute(youtubeChannelId: string) {
    return `${config.YOUTUBE_CHANNEL_URL}/${youtubeChannelId}`;
  }

  static getDashboardRoute() {
    return '/dashboard';
  }

  static getYoutubeVideosRoute() {
    return '/youtube-videos';
  }

  static getYoutubeSpecifyVideoRoute(videoId: string) {
    return `/youtube-videos/${videoId}`;
  }

  static getYoutubeChannelsRoute() {
    return '/youtube-channels';
  }

  static getYoutubeSpecifyChannelRoute(channelId: string | number) {
    return `/youtube-channels/${channelId}`;
  }

  static getSpecifyChannelVideos(channelId: string, filters?: IFilterDto) {
    return `/youtube-channels/${channelId}/videos${this.applyFilters(filters)}`;
  }

  static getFinancialReportsRoute() {
    return '/financial-reports';
  }

  static getSpecifyFinancialReportRoute(reportId: string) {
    return `/financial-reports/${reportId}`;
  }

  static getRevenuesPageRoute() {
    return '/revenues';
  }

  static getLoginRoute() {
    return '/auth/login';
  }

  static getLogoutRoute() {
    return '/auth/logout';
  }

  static getAuthRoute() {
    return '/auth';
  }

  static getForgotPasswordRoute() {
    return '/auth/forgot-password';
  }

  static getProfileRoute() {
    return '/profile';
  }

  static getDownloadsRoute() {
    return '/downloads';
  }

  static getDownloadRoute(id: EntityId) {
    return `/downloads/${id}`;
  }

  static getNotFoundRoute() {
    return '/not-found';
  }

  static getResetPasswordRoute(token: string) {
    return `/auth/reset-password/${token}`;
  }

  static getRegistrationRoute() {
    return '/auth/registration';
  }

  static getSupportRoute() {
    return '/support';
  }

  static getSupportTicketRoute(id: number | string) {
    return '/support/' + id;
  }

  static getUserAgreementRoute() {
    return '/auth/agreement';
  }

  static getUserAgreementContentRoute(lang: AppLocale) {
    if (lang === AppLocale.RU) {
      return '/assets/static/agreement_ru.html';
    }

    return '/assets/static/agreement_en.html';
  }

  static getAdvertisementsRoute() {
    return '/advertisements';
  }

  static getCreateAdvertisementRequestRoute() {
    return `${this.getAdvertisementsRoute()}/create`;
  }

  static getEditAdvertisementRequestRoute(id: string | number) {
    return `${this.getAdvertisementsRoute()}/${id}/edit`;
  }

  static getSpecifyAdvertisementRequestRoute(id: string | number) {
    return `${this.getAdvertisementsRoute()}/${id}`;
  }

  static getKnowledgeBaseRoute() {
    return '/knowledge-base';
  }

  static getSpecifyArticleRoute(articleId: string | number) {
    return `${this.getKnowledgeBaseRoute()}/${articleId}`;
  }

  static getCreateArticleRoute(chapterId: string | number) {
    return `${this.getKnowledgeBaseRoute}/${chapterId}/create`;
  }

  private static applyFilters(filters: IFilterDto | undefined): string {
    if (!filters) return '';
    return (
      '?' +
      Object.keys(filters)
        .map(key => {
          return `${key}=${filters[key as keyof IFilterDto]}`;
        })
        .join('&')
    );
  }
}

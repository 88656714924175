import React, {useState} from 'react';
import {
  IYoutubeFinancialBulkReportsDto,
  REPORT_TYPE,
} from '../../../api/financial-report-api/IYoutubeFinancialBulkReportsDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {ReportsCard} from './reports-card';
import {saveAs} from 'file-saver';
import {IPaginationInfo} from '../../../api/Paginator';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {toast} from 'react-toastify';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';
import {AdminRoutes} from '../../../../configs/routes';
import {BaseListPage} from '../../base/base-list-page';
import {useBaseListPage} from '../../base/base-list-page-context';

const ICONS = {
  PLUS: require('../../../images/svg/Plus.svg'),
};

export const ReportsDownloadPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = new FinancialReportApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [reports, setReports] = useState<Array<IYoutubeFinancialBulkReportsDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const {appliedQueryParams} = useBaseListPage();


  const fetchReports = async (query?: IQueryParams) => {
    try {
      setLoading(true);
      const response = await api.getYoutubeFinancialReports(query);
      setReports(response.data.items);
      setPaginationInfo(response.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = async (bulkReportId: number, reportType: REPORT_TYPE) => {
    try {
      const result = await api.downloadFinancialReport(bulkReportId, reportType + '_FILE_NAME');
      saveAs(result.content, result.fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const handleUploadAffiliateReport = async (bulkReportId: number, value: IYoutubeFinancialBulkReportsDto) => {
    setReports((prev) => {
      const findIndex = prev.findIndex(r => r.id === bulkReportId);
      return [
        ...prev.slice(0, (findIndex - 1) > 0 ? findIndex - 1 : 0),
        value,
        ...prev.slice(findIndex + 1),
      ];
    });
  };

  const handleTryRepeatProcess = async (bulkReportId: number) => {
    try {
      await api.tryAgainProcessBulkReport(bulkReportId);
      await fetchReports(appliedQueryParams);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };


  const renderReports = () => {
    if (reports.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return (
      <>
        {
          reports.map((r, index) => {
            return (
              <ReportsCard
                key={index}
                reportsRecord={r}
                onDownloadClick={handleDownloadClick}
                onTryRepeatProcessClick={handleTryRepeatProcess}
                onRefetch={fetchReports}
                onSuccessUploadAffiliateReport={handleUploadAffiliateReport}
              />
            );
          })
        }
      </>
    );
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loading}
      pagination={{info: paginationInfo}}
      pageTitle={intl.formatMessage({id: 'FINANCIAL_REPORT'})}
      fetchData={fetchReports}
      toolbarConfig={[
        {
          title: intl.formatMessage({id: 'REPORTS_UPLOAD'}),
          to: AdminRoutes.getUploadYoutubeFinancialReports(),
          type: 'LINK',
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
        },
      ]}
      className={{cardBody: 'bg-light'}}>
      {renderReports()}
    </BaseListPage>
  );
};

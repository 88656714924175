import React, {useEffect, useMemo, useState} from 'react';
import {TransactionsApi} from '../../../api/transaction-api/transactions-api';
import {Transaction, TransactionStatus, TransactionType} from '../../../api/transaction-api/Transaction';
import {IPaginationInfo} from '../../../api/Paginator';
import {TransactionsTable} from './transactions-table';
import {JsonParam} from 'use-query-params';
import {SelectApi} from '../../../api/select-api';
import {FormattedMessage, useIntl} from 'react-intl';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {ITransactionsAggregation} from '../../../api/transaction-api/transactions-response-contracts';
import {DropdownToolBarItemAction} from '../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../hooks/use-loading';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {SelectsValuesDTOs} from '../../../components/Inputs/InputSelect';
import {BaseListPage} from '../../base/base-list-page';
import {HandleTransactionActionPayload, useTransactionActionHandler} from './useTransactionActionHandler';
import {useBaseListPage} from '../../base/base-list-page-context';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export enum TransactionAction {
  REJECT = 'REJECT',
  CONFIRM = 'CONFIRM',
  REPORT = 'REPORT',
  INCOME_REPORT = 'INCOME_REPORT',
  CANCEL = 'CANCEL',
  WITHDRAWING = 'WITHDRAWING',
  DEBIT = 'DEBIT',
  INCOME = 'INCOME',
  TAX = 'TAX',
  CONFIRM_ALL = 'CONFIRM_ALL',
}

export const TransactionsPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = new TransactionsApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo<ITransactionsAggregation> | null>(null);
  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({
    is_for_copies: [
      {id: '1', title: intl.formatMessage({id: 'ONLY_COPIES'})},
      {id: '0', title: intl.formatMessage({id: 'NOT_COPIES'})},
    ],
    is_for_referral: [
      {id: '1', title: intl.formatMessage({id: 'WITH_REFERRAL'})},
      {id: '0', title: intl.formatMessage({id: 'WITHOUT_REFERRAL'})},
    ],
    type: [
      TransactionType.ADJUSTMENT_BALANCE,
      TransactionType.FROM_YOUTUBE_TO_PLAYNETWORK,
      TransactionType.PAYMENT_FROM_CONTRACTOR_TO_PLAYNETWORK,
      TransactionType.PAYMENT_YOUTUBE_REVENUE_FOR_CONTRACTOR,
      TransactionType.PAYMENT_OF_TAX,
      TransactionType.WITHDRAWAL,
      TransactionType.WITHDRAWAL_COMMISSION,
      TransactionType.WITHDRAWAL_TO_MC_PAY,
    ].map(t => ({id: t, title: intl.formatMessage({id: 'TRANSACTION_TYPE_' + t})})),
    status: [
      TransactionStatus.CONFIRMED,
      TransactionStatus.REJECTED,
      TransactionStatus.WAITING_CONFIRM,
      TransactionStatus.CANCELED,
    ].map(s => ({
      id: s,
      title: intl.formatMessage({id: 'TRANSACTION_STATUS_' + s}),
    })),
  });
  const {appliedQueryParams} = useBaseListPage();
  const dropdownCommonActions = useMemo<Array<DropdownToolBarItemAction<TransactionAction>>>(
    () => [
      {
        title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
        value: TransactionAction.REPORT,
      },
      {
        title: intl.formatMessage({id: 'CHANNEL_TRANSACTIONS_INCOME_REPORT'}),
        value: TransactionAction.INCOME_REPORT,
      },
      {
        title: intl.formatMessage({id: 'CONFIRM_ALL_TRANSACTIONS'}),
        value: TransactionAction.CONFIRM_ALL,
      },
      {
        title: intl.formatMessage({id: 'CREATE_ACTION_WITHDRAWING_TRANSACTION'}),
        value: TransactionAction.WITHDRAWING,
      },
      {
        title: intl.formatMessage({id: 'CREATE_ACTION_DEBIT_TRANSACTION'}),
        value: TransactionAction.DEBIT,
      },
      {
        title: intl.formatMessage({id: 'CREATE_ACTION_TAX_TRANSACTION'}),
        value: TransactionAction.TAX,
      },
      {
        title: intl.formatMessage({id: 'CREATE_ACTION_INCOME_TRANSACTION'}),
        value: TransactionAction.INCOME,
      },
    ],
    [],
  );

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'transactions_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'ID'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.ID, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'ID'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.ID, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'account_id',
          placeholder: intl.formatMessage({id: 'ACCOUNTS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'user_id',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'y_channel_id',
          placeholder: intl.formatMessage({id: 'CHANNELS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'is_for_copies',
          placeholder: intl.formatMessage({id: 'COPIES'}),
        },
        {
          type: FilterType.SELECT,
          name: 'is_for_referral',
          placeholder: intl.formatMessage({id: 'REFERRAL'}),
        },
        {
          type: FilterType.SELECT,
          name: 'type',
          placeholder: intl.formatMessage({id: 'TYPE'}),
        },
        {
          type: FilterType.COMPOSITE,
          name: 'period',
          extractFields: true,
          queryConfig: {
            name: 'period',
            type: JsonParam,
          },
          items: [
            {
              required: true,
              clearable: true,
              type: FilterType.DATE,
              name: 'period|start_date',
              placeholder: intl.formatMessage({id: 'PERIOD_START_DATE'}),
            },
            {
              required: true,
              clearable: true,
              type: FilterType.DATE,
              name: 'period|end_date',
              placeholder: intl.formatMessage({id: 'PERIOD_END_DATE'}),
            },
          ],
        },
      ],
    });
  }, [selectValues, loadings.select]);

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        user_id: (await selectApi.getUsers()).data.items,
        account_id: (await selectApi.getAccounts()).data.items,
        y_channel_id: (await selectApi.getYoutubeChannels()).data.items,
        contractor_id: (await selectApi.getContractors()).data.items,
      });
    } catch (e: any) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const fetchTransactions = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getTransactions(filters);
      setTransactions(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e: any) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };
  const {handleTransactionAction, reportLoading} = useTransactionActionHandler(fetchTransactions);

  const handleCommonTransactionActions = async (action: TransactionAction) => {
    await handleTransactionAction({
      type: action,
      data: {filters: appliedQueryParams.filters},
    } as HandleTransactionActionPayload);
  };

  const renderTransactions = () => {
    if (transactions.length === 0)
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );

    return (
      <div style={{fontSize: 12}}>
        <TransactionsTable
          transactions={transactions}
          onSelectAction={handleTransactionAction}
          aggregationValues={paginationInfo?.aggregationValues ?? null}
        />
      </div>
    );
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      toolbarConfig={[
        {
          loading: reportLoading,
          title: intl.formatMessage({id: 'ACTIONS'}),
          type: 'DROPDOWN',
          actions: dropdownCommonActions,
          className: 'font-weight-bolder text-uppercase btn-light-success',
          onSelect: handleCommonTransactionActions,
        },
      ]}
      pagination={{
        info: paginationInfo,
        aggregationValues: {
          [intl.formatMessage({id: 'TOTAL_TRANSACTIONS_SUM'})]:
            paginationInfo?.aggregationValues?.total_transactions_sum != null
              ? intl.formatNumber(Number(paginationInfo.aggregationValues.total_transactions_sum), {
                currency: 'USD',
                style: 'currency',
              })
              : 'N/A',
        },
      }}
      fetchData={fetchTransactions}>
      {renderTransactions()}
    </BaseListPage>
  );
};

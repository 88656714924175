import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {UserType} from '../../../api/DTOs/IUserDto';
import {InputText} from '../../Inputs/InputText';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';
import {InputSelect, SelectOptions} from '../../Inputs/InputSelect';

interface IModalCreateUserProps {
  visible: boolean;

  email: string;
  password: string;
  password_confirmation: string;
  jurisdiction_id: string | number | null;
  jurisdictions: SelectOptions;
  type: UserType;
  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;
  allowPaymentRequestByRequisites: boolean;

  onChangeAllowWithdrawalByRequisites(value: boolean): void;

  onEmailChange(email: string): void;

  onPasswordChange(password: string): void;

  onPasswordConfirmationChange(password: string): void;

  onUserTypeChange(type: UserType): void;

  onJurisdictionChange(jurisdictionId: string | number | null): void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateUser: React.FC<IModalCreateUserProps> = ({
                                                                   email,
                                                                   password,
                                                                   allowPaymentRequestByRequisites,
                                                                   password_confirmation,
                                                                   visible,
                                                                   type,
                                                                   jurisdiction_id,
                                                                   jurisdictions,
                                                                   error,
                                                                   validationErrors,
                                                                   onHide,
                                                                   onEmailChange,
                                                                   onChangeAllowWithdrawalByRequisites,
                                                                   onUserTypeChange,
                                                                   onPasswordChange,
                                                                   onPasswordConfirmationChange,
                                                                   onOkClick,
                                                                   onJurisdictionChange,
                                                                 }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'CREATE_USER'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className="form-group">
              <ValidateErrorWrapper message={getValidationErrorMessage('email', validationErrors)}>
                <InputText
                  required
                  value={email}
                  label={intl.formatMessage({id: 'EMAIL'})}
                  hasError={!!getValidationErrorMessage('email', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => onEmailChange(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('jurisdiction_id', validationErrors)}>
                <InputSelect
                  value={jurisdiction_id}
                  hasError={validationErrors?.jurisdiction_id != null}
                  options={jurisdictions ?? []}
                  onChange={onJurisdictionChange}
                  label={intl.formatMessage({id: 'AUTH.INPUT.JURISDICTION'})}
                  placeholder={intl.formatMessage({id: 'AUTH.INPUT.JURISDICTION'})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('password', validationErrors)}>
                <InputText
                  required
                  value={password}
                  type={'password'}
                  label={intl.formatMessage({id: 'PASSWORD'})}
                  hasError={!!getValidationErrorMessage('password', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => onPasswordChange(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('password_confirmation', validationErrors)}>
                <InputText
                  required
                  value={password_confirmation}
                  type={'password'}
                  label={intl.formatMessage({id: 'PASSWORD_CONFIRMATION'})}
                  hasError={!!getValidationErrorMessage('password_confirmation', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => onPasswordConfirmationChange(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>

              <InputCheckbox
                label={intl.formatMessage({id: 'ADMIN_PRIVILEGES'})}
                value={type === UserType.ADMIN}
                onChange={() => onUserTypeChange(type === UserType.ADMIN ? UserType.USER : UserType.ADMIN)}
              />

              <ValidateErrorWrapper
                message={getValidationErrorMessage('allow_payment_request_by_requisites', validationErrors)}>
                <InputCheckbox
                  label={intl.formatMessage({id: 'ALLOW_WITHDRAWAL_BY_REQUISITES'})}
                  value={allowPaymentRequestByRequisites}
                  onChange={() => onChangeAllowWithdrawalByRequisites(!allowPaymentRequestByRequisites)}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};

import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../components/default-table';
import {AdminRoutes, Routes} from '../../../configs/routes';
import {
  ISupportTicketDto,
  SupportTicketStatus,
  SupportTicketType,
} from '../../api/support-tickets-api/ISupportTicketDto';
import {BadgeCustom} from '../../components/badge-custom';
import {TableUtils} from '../../components/table-utils';
import {values} from 'lodash';

interface ITicketsTableProps {
  isAdmin?: boolean;
  tickets: Array<ISupportTicketDto>;
}

export const TicketsTable = ({tickets, isAdmin}: ITicketsTableProps) => {
  const intl = useIntl();
  const data = tickets;

  const columns = useMemo(() => {
    return [
      {
        id: 'ID',
        Header: TableUtils.renderHeaderForNumericContent('ID'),
        maxWidth: 50,
        minWidth: 50,
        forceApplySize: true,
        accessor: (br: ISupportTicketDto) => br.id,
        Cell: ({value: id}: {value: number}) => {
          return TableUtils.renderNumericContent(<>
            <Link to={isAdmin
              ? AdminRoutes.getSupportTicketRoute(id)
              : Routes.getSupportTicketRoute(id)
            }>
              {id}
            </Link>
          </>);
        },
      },
      {
        id: 'UnreadedMessages',
        Header: '',
        maxWidth: 50,
        minWidth: 50,
        forceApplySize: true,
        accessor: (br: ISupportTicketDto) => br.countUnreadByAdmin,
        Cell: ({value: countUnreadByAdmin}: {value: number}) => {
          return TableUtils.renderNumericContent(<>
            {countUnreadByAdmin !== 0 &&
              <div className={'d-flex'} style={{
                borderRadius: '100%',
                backgroundColor: '#c6ea4b',
                width: '25px',
                height: '25px',
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                {countUnreadByAdmin}
              </div>
            }
          </>);
        },
      },
      {
        id: 'User',
        Header: intl.formatMessage({id: 'USER'}),
        maxWidth: 300,
        minWidth: 350,
        accessor: (br: ISupportTicketDto) => br,
        Cell: ({value}: {value: ISupportTicketDto}) => {
          return TableUtils.renderBaseContent(<>
            <Link to={isAdmin
              ? AdminRoutes.getSupportTicketRoute(value.id)
              : Routes.getSupportTicketRoute(value.id)
            }>
              {value.authorName}
            </Link>
          </>);
        },
      },
      {
        id: 'UpdatedAt',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: ISupportTicketDto) => br.updatedAt,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value)} {intl.formatTime(value)}
            </>,
          );
        },
      },
      {
        id: 'CreatedAt',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: ISupportTicketDto) => br.createdAt,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(<>
            {intl.formatDate(value)} {intl.formatTime(value)}
          </>);
        },
      },
    ];
  }, []);


  return <DefaultTable data={data} columns={columns} />;
};

import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IResponseBase} from '../response-contracts/base-response';
import {REPORT_TYPE} from './IYoutubeFinancialBulkReportsDto';
import {IQueryParams} from '../DTOs/IFilterDtos';
import {
  IYoutubeFinancialPeriodResponse,
  IYoutubeFinancialReportsResponse,
} from './financial-reports-response-contracts';

export class FinancialReportApi extends BaseEndpoint {
  //#region ADMIN Endpoints
  public async getYoutubeFinancialPeriods(): Promise<IYoutubeFinancialPeriodResponse> {
    const response = await this.get<IYoutubeFinancialPeriodResponse>(
      `${config.BASE_API_URL}/admin/youtube-financial-reports/available-upload-months`,
    );
    return response.data;
  }

  public async sendYoutubeFinancialReport(cms_id: number | null, period: string | null, files: {
    [key: string]: File
  }): Promise<IResponseBase> {
    const formData = new FormData();
    formData.append('fields[month_id]', period || '');
    formData.append('fields[cms_id]', cms_id ? cms_id.toString() : '');
    Object.keys(files).forEach(key => {
      const type = key as REPORT_TYPE;
      formData.append(`fields[${type}]`, files[type]);
    });

    const response = await this.postFile(`${config.BASE_API_URL}/admin/youtube-financial-reports`, formData);
    return response.data;
  }

  public async sendAffiliateFinancialReport(bulkReportId: number, file: File): Promise<IResponseBase> {
    const formData = new FormData();
    formData.append(`fields[AFFILIATE_PAYMENT_SUMMARY]`, file);

    const response = await this.postFile(`${config.BASE_API_URL}/admin/youtube-financial-reports/${bulkReportId}/affiliatePaymentSummaryReport`, formData);
    return response.data;
  }

  public async getYoutubeFinancialReports(filters?: IQueryParams): Promise<IYoutubeFinancialReportsResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IYoutubeFinancialReportsResponse>(
      `${config.BASE_API_URL}/admin/youtube-financial-reports?${query}`,
    );
    return response.data;
  }

  public async downloadFinancialReport(
    bulkReportId: number,
    fileName: string,
  ): Promise<{fileName: string; content: Blob}> {
    const response = await this.getFile(
      `${config.BASE_API_URL}/admin/youtube-financial-reports/${bulkReportId}/${fileName}/download`,
    );
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }

  public async tryAgainProcessBulkReport(bulkReportId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/youtube-financial-reports/${bulkReportId}/repeat`,
      {},
    );
    return response.data;
  }

  //#endregion

  //#region USER Endpoints
  public async getReportPeriods(): Promise<IYoutubeFinancialPeriodResponse> {
    const response = await this.get<IYoutubeFinancialPeriodResponse>(
      `${config.BASE_API_URL}/financial-reports/available-months`,
    );
    return response.data;
  }

  public async sendSelfGenerateReportRequest(
    contract_id: string | null,
    months_ids: Array<string>,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/financial-reports/detailed-excel-report`, {
      filters: {
        contract_id,
        months_ids,
      },
    });
    return response.data;
  }

  public async sendUserGenerateReportRequest(
    userId: number,
    contract_id: number | string | null,
    months_ids: Array<string>,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/users/${userId}/detailed-excel-report`,
      {
        filters: {
          contract_id,
          months_ids,
        },
      },
    );
    return response.data;
  }

  //#endregion
}

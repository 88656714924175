import {REPORT_TYPE} from '../financial-report-api/IYoutubeFinancialBulkReportsDto';

export enum TransactionType {
  FROM_YOUTUBE_TO_PLAYNETWORK = 'FROM_YOUTUBE_TO_PLAYNETWORK',
  PAYMENT_YOUTUBE_REVENUE_FOR_CONTRACTOR = 'PAYMENT_YOUTUBE_REVENUE_FOR_CONTRACTOR',
  PAYMENT_OF_TAX = 'PAYMENT_OF_TAX',
  PAYMENT_FROM_CONTRACTOR_TO_PLAYNETWORK = 'PAYMENT_FROM_CONTRACTOR_TO_PLAYNETWORK',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_COMMISSION = 'WITHDRAWAL_COMMISSION',
  ADJUSTMENT_BALANCE = 'ADJUSTMENT_BALANCE',
  WITHDRAWAL_TO_MC_PAY = 'WITHDRAWAL_TO_MC_PAY',
}

export interface Transaction {
  id: number;
  type: TransactionType;
  createdAt: string;
  value: number;
  currency: Currency;
  description: string;
  localizedDescription: string;

  periodMonth: number | null;
  periodYear: number | null;

  fromContractorId: number;
  fromContractorName: string;
  fromAccountId: number;
  fromAccountName: string;

  toContractorId: number;
  toContractorName: string;
  toAccountId: number;
  toAccountName: string;

  youtubeFinancialReportUploadHistoryItemId: number | null;
  youtubeFinancialReportUploadHistoryItemYear: number | null;
  youtubeFinancialReportUploadHistoryItemMonth: number | null;
  youtubeFinancialReportUploadHistoryItemReportType: REPORT_TYPE | null;

  addendumId: number | null;
  youtubeChannelId: string | null;
  youtubeChannelTitle: string | null;

  canReject: boolean;
  canConfirm: boolean;
  canCancel: boolean;

  status: TransactionStatus;
  isCanceled: boolean;
  rejectReason: string | null;
  cancelReason: string | null;

  isMcPayCreateTransactionConfirm: boolean | null;
  is_for_referral: boolean | null;
  is_for_copies: boolean | null;
}

export enum McPayCreateTransactionStatus {
  'WAITING_SEND_TO_MC_PAY' = 0,
  'PROCESS' = 1,
  'SUCCESS' = 2,
  'ERROR' = 3,
}

export enum TransactionStatus {
  WAITING_CONFIRM = 'WAITING_CONFIRM',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export enum Currency {
  USD = 'USD',
  RUB = 'RUB',
}

import React, {useEffect, useState} from 'react';
import {
  IYoutubeFinancialBulkReportsDto,
  REPORT_FILE_NAME,
  REPORT_TYPE,
  ReportsProcessingStatus,
} from '../../../api/financial-report-api/IYoutubeFinancialBulkReportsDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {FinancialReportsTable} from './financial-reports-table';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {capitalizeFirstLetter} from '../../../utils/utils';
import {BadgeCustom} from '../../../components/badge-custom';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {ICONS} from '../../../images/images';
import {useFilePicker} from 'use-file-picker';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';
import {toast} from 'react-toastify';
import {Spinner} from 'react-bootstrap';

interface IReportsCardProps {
  reportsRecord: IYoutubeFinancialBulkReportsDto;

  onSuccessUploadAffiliateReport(bulkReportId: number, value: IYoutubeFinancialBulkReportsDto): any;

  onDownloadClick(bulkReportId: number, reportType: REPORT_TYPE): any;

  onTryRepeatProcessClick(bulkReportId: number): void;

  onRefetch(): void;

}

export const ReportsCard: React.FC<IReportsCardProps> = ({
                                                           reportsRecord,
                                                           onDownloadClick,
                                                           onTryRepeatProcessClick,
                                                           onSuccessUploadAffiliateReport,
                                                         }) => {
  const intl = useIntl();

  const api = new FinancialReportApi();
  const [isLoading, setIsLoading] = useState(false);
  const {openFilePicker, plainFiles, clear} = useFilePicker({
    accept: ['.csv'],
    multiple: false,
  });

  useEffect(() => {
    void handleAffiliateReportSelected();
  }, [plainFiles]);

  const handleAffiliateReportSelected = async () => {
    if (plainFiles.length !== 0) {
      const file = plainFiles[0];
      setIsLoading(true);
      try {
        const response = await api.sendAffiliateFinancialReport(reportsRecord.id, file);
        toast.success(intl.formatMessage({id: 'SUCCESS_UPLOAD_AFFILIATE_REPORT'}));
        onSuccessUploadAffiliateReport(reportsRecord.id, response.data.entity);
      } catch (e) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      } finally {
        clear();
        setIsLoading(false);
      }
    }
  };


  const handleDownloadClick = (type: REPORT_TYPE): boolean => {
    return onDownloadClick(reportsRecord.id, type);
  };

  const handleRepeatButtonClick = async () => {
    onTryRepeatProcessClick(reportsRecord.id);
  };

  const fileNamesWithType = Object.entries(reportsRecord).filter(v => v[0].indexOf('FILE_NAME') != -1 && v[1] != null);
  let colorVariant;
  switch (reportsRecord.status) {
    case ReportsProcessingStatus.CREATED:
      colorVariant = 'secondary';
      break;
    case ReportsProcessingStatus.IN_WORK:
      colorVariant = 'primary';
      break;
    case ReportsProcessingStatus.ERROR:
      colorVariant = 'danger';
      break;
    case ReportsProcessingStatus.PROCESSED:
      colorVariant = 'success';
      break;
    default:
      colorVariant = 'default';
  }

  return (
    <>
      <div className="card card-custom border gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {capitalizeFirstLetter(
                intl.formatDate(new Date(reportsRecord.year, reportsRecord.month - 1), {
                  month: 'long',
                  year: 'numeric',
                }),
              )}
            </h3>
          </div>
          <div className={'card-toolbar'}>
            <>
              <Toolbar
                className={'mr-2'}
                items={[
                  {
                    type: 'DROPDOWN',
                    visible: reportsRecord.status === ReportsProcessingStatus.ERROR,
                    title: intl.formatMessage({id: reportsRecord.status}),
                    actions: [{title: intl.formatMessage({id: 'TRY_AGAIN'}), value: '0'}],
                    className: 'btn-danger',
                    onSelect: handleRepeatButtonClick,
                  },
                  {
                    type: 'CUSTOM',
                    visible: (reportsRecord?.status === ReportsProcessingStatus.PROCESSED && reportsRecord?.[REPORT_FILE_NAME.AFFILIATE_PAYMENT_SUMMARY_FILE_NAME] == null),
                    render: () => {
                      return <button disabled={isLoading} onClick={openFilePicker}
                                     className={'btn btn-light-primary btn-sm'}>
                        {isLoading ? (
                          <Spinner className={'mr-1'} size={'sm'} animation={'border'} />
                        ) : (
                          <i className="flaticon2-file" />
                        )}
                        <FormattedMessage id={'UPLOAD_AFFILIATE_REPORT'} />
                      </button>;
                    },
                  },
                ]}
              />
              {
                reportsRecord[REPORT_FILE_NAME.AFFILIATE_PAYMENT_SUMMARY_FILE_NAME] != null &&
                <BadgeCustom variant={'success'} pill className={'mr-2'}>
                  <FormattedMessage id={'AFFILIATE_REPORT_EXISTS'} />
                </BadgeCustom>
              }
              <BadgeCustom variant={colorVariant} pill>
                <FormattedMessage id={reportsRecord.status} />
              </BadgeCustom>
            </>
          </div>
        </div>
        <div className="card-body">
          <AlertCustom
            text={reportsRecord.errorDescription}
            type={'light-danger'}
            visible={reportsRecord.errorDescription != null}
            iconClassName={'svg-icon-danger'}
          />

          <FinancialReportsTable
            onDownloadClick={handleDownloadClick}
            reports={fileNamesWithType.map(f => {
              return {
                type: f[0].replace('_FILE_NAME', '') as REPORT_TYPE,
                fileName: f[1] as string,
                cms_id: reportsRecord.cms_id,
                cms_name: reportsRecord.cms_name,
              };
            })}
          />
        </div>
      </div>
    </>
  );
};


import React, {useMemo} from 'react';
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl';
import {IUserDto, UserType} from '../../../api/DTOs/IUserDto';
import {UserAction} from './user-management-page';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../components/default-table';
import SVG from 'react-inlinesvg';
import {AdminRoutes} from '../../../../configs/routes';
import {BadgeCustom} from '../../../components/badge-custom';
import {RowActionButton} from '../../../components/table-actions-button';
import {TableUtils} from '../../../components/table-utils';
import {ICONS} from '../../../images/images';

interface IUsersTableProps {
  users: Array<IUserDto>;

  onSelect(userId: number, action: UserAction): Promise<boolean>;
}

export const UsersTable = ({users, onSelect}: IUsersTableProps) => {
  const intl = useIntl();
  const data = users;
  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 50,
        minWidth: 50,
        forceApplySize: true,
        accessor: (br: IUserDto) => br.id,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyUserManagementRoute(value)}>{value}</Link>,
          );
        },
      },
      {
        id: 'Email',
        Header: intl.formatMessage({id: 'EMAIL'}),
        maxWidth: 250,
        accessor: (br: IUserDto) => br,
        Cell: ({value}: {value: IUserDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyUserManagementRoute(value.id)}>{value.email}</Link>,
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IUserDto) => br,
        Cell: ({value}: {value: IUserDto}) => {
          return TableUtils.renderBaseContent(
            value.contractor_id != null ? (
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>{value.contractor_name}</Link>
            ) : (
              '--'
            ),
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'USER_TYPE'}),
        maxWidth: 150,
        forceApplySize: true,
        accessor: (br: IUserDto) => br.type,
        Cell: ({value: userType}: {value: UserType}) => {
          const colorVariant = userType === UserType.ADMIN ? 'primary' : 'secondary';
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={colorVariant}>
              <FormattedMessage id={userType} />
            </BadgeCustom>,
          );
        },
      },
      {
        id: 'Requisites',
        Header: intl.formatMessage({id: 'REQUISITES'}),
        maxWidth: 100,
        forceApplySize: true,
        accessor: (br: IUserDto) => br,
        Cell: ({value}: {value: IUserDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {value.hasPaymentRequisites ? (
                <span className="svg-icon svg-icon-success mr-1">
                  <SVG src={ICONS.CHECK} />
                </span>
              ) : (
                '—'
              )}
            </>,
          );
        },
      },
      {
        id: 'WithdrawalToRequisites',
        Header: intl.formatMessage({id: 'WITHDRAWAL_BY_REQUISITES'}),
        maxWidth: 120,
        forceApplySize: true,
        accessor: (br: IUserDto) => br,
        Cell: ({value}: {value: IUserDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {value.allow_payment_request_by_requisites ? (
                <span className="svg-icon svg-icon-success mr-1">
                  <SVG src={ICONS.CHECK} />
                </span>
              ) : (
                <span className="svg-icon svg-icon-danger mr-1">
                  <SVG src={ICONS.CROSS} />
                </span>
              )}
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 150,
        forceApplySize: true,
        accessor: (br: IUserDto) => br.created_at,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(
            <>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 150,
        forceApplySize: true,
        accessor: (br: IUserDto) => br.updated_at,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(
            <>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'BLOCKED_AT'}),
        maxWidth: 150,
        forceApplySize: true,
        accessor: (br: IUserDto) => br.blocked_at,
        Cell: ({value}: {value: string | null}) => {
          return TableUtils.renderBaseContent(
            <>
              {value === null ? (
                '—'
              ) : (
                <>
                  <FormattedDate value={value} /> <FormattedTime value={value} />
                </>
              )}
            </>,
          );
        },
      },
      {
        id: 'Action',
        maxWidth: 50,
        accessor: (br: IUserDto) => br,
        Cell: ({value}: {value: IUserDto}) => {
          const actions = [
            {title: intl.formatMessage({id: 'RESET_PASSWORD'}), value: UserAction.RESET_PASSWORD},
            {title: intl.formatMessage({id: 'DELETE'}), value: UserAction.DELETE},
            value.blocked_at != null
              ? {title: intl.formatMessage({id: 'UNBLOCK'}), value: UserAction.UNBLOCK}
              : {title: intl.formatMessage({id: 'BLOCK'}), value: UserAction.BLOCK},
            {title: intl.formatMessage({id: 'EDIT'}), value: UserAction.EDIT},
            {title: intl.formatMessage({id: 'REPORT'}), value: UserAction.DOWNLOAD_REPORT},
          ];
          return TableUtils.renderActionButton(
            <RowActionButton
              rowDto={value}
              actions={actions}
              onSelect={(rowDto, action) => onSelect(rowDto.id, action)}
            />,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};

import {REPORT_TYPE} from '../../../api/financial-report-api/IYoutubeFinancialBulkReportsDto';

export const ReportsInfo = {
  ADS: [
    {
      isRequired: true,
      type: REPORT_TYPE.VIDEO_SUMMARY,
      category: 'ADS',
      humanDescription: 'YOUTUBE_REPORT_TYPE_VIDEO_SUMMARY',
      fileMask: 'YouTube_Pladform_TV_M_20210401_video_summary_v1-1.csv.gz',
      acceptFileExtension: '.gz',
    },
    {
      isRequired: true,
      type: REPORT_TYPE.CLAIM_SUMMARY,
      category: 'ADS',
      humanDescription: 'YOUTUBE_REPORT_TYPE_CLAIM_SUMMARY',
      fileMask: 'YouTube_Pladform_TV_M_20210401_claim_summary_v1-1.csv.gz',
      acceptFileExtension: '.gz',
    },
  ],
  SUBSCRIPTION: [
    {
      isRequired: true,
      type: REPORT_TYPE.RED_RAWDATA_VIDEO,
      humanDescription: 'YOUTUBE_REPORT_TYPE_RED_RAWDATA_VIDEO',
      fileMask: 'YouTube_Pladform_TV_M_YYYYMMDD_YYYYMMDD_red_rawdata_video_v1_1.csv.gz',
      acceptFileExtension: '.gz',
    },
    {
      isRequired: true,
      type: REPORT_TYPE.RED_MUSIC_RAWDATA_VIDEO,
      humanDescription: 'YOUTUBE_REPORT_TYPE_RED_MUSIC_RAWDATA_VIDEO',
      fileMask: 'YouTube_Pladform_TV_M_YYYYMMDD_YYYYMMDD_red_music_rawdata_video_v1-1.csv.gz',
      acceptFileExtension: '.gz',
    },
  ],
  COMPENSATION: [
    {
      isRequired: true,
      type: REPORT_TYPE.ADJ_CLAIM_SUMMARY,
      humanDescription: 'YOUTUBE_REPORT_TYPE_ADJ_CLAIM_SUMMARY',
      fileMask: 'YouTube_Pladform_TV_M_YYYYMMDD_ADJ_claim_summary_v1-1.csv.gz',
      acceptFileExtension: '.gz',
    },
    {
      isRequired: true,
      type: REPORT_TYPE.ADJ_VIDEO_SUMMARY,
      humanDescription: 'YOUTUBE_REPORT_TYPE_ADJ_VIDEO_SUMMARY',
      fileMask: 'YouTube_Pladform_TV_M_YYYYMMDD_ADJ_video_summary_v1-1.csv.gz',
      acceptFileExtension: '.gz',
    },
  ],
  SHORTS: [
    {
      isRequired: true,
      type: REPORT_TYPE.SHORTS_ADS,
      humanDescription: 'YOUTUBE_REPORT_TYPE_SHORTS_ADS',
      fileMask: 'YouTube_Pladform_M_20230201_20230228_monthly_shorts_non_music_ads_video_summary_v1-0.csv.gz',
      acceptFileExtension: '.gz',
    },
    {
      isRequired: true,
      type: REPORT_TYPE.SHORTS_SUBSCRIPTION,
      humanDescription: 'YOUTUBE_REPORT_TYPE_SHORTS_SUBSCRIPTION',
      fileMask: 'YouTube_Pladform_M_20230201_20230228_monthly_shorts_non_music_subscription_video_summary_v1-0.csv.gz',
      acceptFileExtension: '.gz',
    },
  ],
  OTHER: [
    {
      isRequired: false,
      type: REPORT_TYPE.ECOMMERCE_PAID_FEATURES,
      humanDescription: 'YOUTUBE_REPORT_TYPE_ECOMMERCE_PAID_FEATURES',
      fileMask: 'YouTube_Pladform_TV_Ecommerce_paid_features_M_YYYYMMDD_YYYYMMDD_v1-1.csv.gz',
      acceptFileExtension: '.zip',
    },
    {
      isRequired: true,
      type: REPORT_TYPE.AFFILIATE_PAYMENT_SUMMARY,
      humanDescription: 'YOUTUBE_REPORT_TYPE_AFFILIATE_PAYMENT_SUMMARY',
      fileMask: 'YouTube_Pladform_TV_M_YYYYMM_affiliate_payment_summary_v1-0.csv',
      acceptFileExtension: '.csv',
    },
  ],
};

import {BaseEndpoint} from '../base-endpoint';
import {IResponseBase} from '../response-contracts/base-response';
import config from '../../../configs/config';
import {IUserDto, UserType} from '../DTOs/IUserDto';
import {
  IUserListResponse,
  IUserResponse,
  IUsersActionsResponse,
  UserMediaCubePayOAuthData,
} from './users-response-contracts';
import {PartialNullable} from '../../../types';
import {EntityId} from '../base/BaseEntity';

export class UsersApi extends BaseEndpoint {
  public async getUsersActions(filters?: any): Promise<IUsersActionsResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IUsersActionsResponse>(`${config.BASE_API_URL}/admin/users-actions?${query}`);
    return response.data;
  }

  public async getUserList(filters?: any): Promise<IUserListResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IUserListResponse>(`${config.BASE_API_URL}/admin/users?${query}`);
    return response.data;
  }

  public async getUser(userId: number): Promise<IUserResponse> {
    const response = await this.get<IUserResponse>(`${config.BASE_API_URL}/admin/users/${userId}`);
    return response.data;
  }

  public async getMediaCubePayOAuthData(): Promise<UserMediaCubePayOAuthData> {
    const response = await this.get<UserMediaCubePayOAuthData>(`${config.BASE_API_URL}/user/media-cube-pay-oauth-data`);
    return response.data;
  }

  public async createUser(
    email: string,
    password: string,
    password_confirmation: string,
    userType: UserType,
    jurisdiction_id: EntityId | null,
    allowPaymentRequestByRequisites: boolean,
  ): Promise<IResponseBase<{item: IUserDto}>> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/users/`, {
      fields: {
        email,
        password,
        jurisdiction_id,
        password_confirmation,
        type: userType,
        allow_payment_request_by_requisites: allowPaymentRequestByRequisites,
      },
    });
    return response.data;
  }

  public async editUser(user: PartialNullable<IUserDto>): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/users/${user.id}`, {
      fields: {
        ...user,
      },
    });

    return response.data;
  }

  public async deleteUser(userId: number): Promise<IResponseBase> {
    const response = await this.delete<IResponseBase>(`${config.BASE_API_URL}/admin/users/${userId}/delete`);
    return response.data;
  }

  public async blockUser(userId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/users/${userId}/block`, {});
    return response.data;
  }

  public async unblockUser(userId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/users/${userId}/unblock`, {});
    return response.data;
  }

  public async resetPasswordForUser(userId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/users/${userId}/reset_password`, {});
    return response.data;
  }
}
